///include /assets/js/app/p/sbproduct.js
"use strict"

class SBBalanceProduct extends SBNonTransferProduct {
    static get prefix() {
        return "balance"
    }
    get codePrefix() {
        return "balance"
    }
    get defaultAbstractPaymentType() {
        return "any"
    }
}

SBProduct.register([
    SBBalanceProduct,
])